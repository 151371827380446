import {ICollapsable} from "../collapsable";
import {getParameter} from "../../utils/querystring";
import {expressEventListener, expressQuerySelector, expressQuerySelectorAll} from "../../common/html";
import {findInsertIndex} from "../../utils/dom-tree";
import {IFilterDependencies, TemplateType, TypeOfFilter} from "../../common/filter";

export function createColorFilter(collapsableComponent: ICollapsable, selectedFiltersContainerEl: HTMLElement, queryString: string, filterCategory: string, filterEl: HTMLElement, containerEl: HTMLElement, possibleUrlValues: string[], deps: IFilterDependencies) {
	const selectedFilterName = filterEl.dataset.filterelement;
	const selectedFilter = decodeURIComponent(getParameter(queryString)).split(',');
	const {
		showSelectedFilters,
		addFilterTemplate,
		updateParametersForUrl,
		removeFromFilterContainer,
		onFilter,
		attachRemoveFilterEvent,
		toggleResetFilters
	} = deps;

	const addColorToFilterContainer = (containerEl: HTMLElement, selectedFiltersContainerEl: HTMLElement, selectedFilterName: string, filterForCategory: string, removeFilterEvent: any) => {
		showSelectedFilters(containerEl, selectedFiltersContainerEl);

		if (!selectedFiltersContainerEl)
			return;

		const selectedFilterEl = expressQuerySelector<HTMLElement>(selectedFiltersContainerEl, `[data-filterforcategory="${filterForCategory}"][data-filterforcategoryvalue="${selectedFilterName}"]`, false);
		if (selectedFilterEl) return;

		const colorFilter = addFilterTemplate(TemplateType.Icon, filterForCategory, selectedFilterName, selectedFilterName, removeFilterEvent);
		const allSelectedColorFilters = expressQuerySelectorAll<HTMLElement>(selectedFiltersContainerEl, `[data-filterforcategory="${filterForCategory}"]`);
		if (allSelectedColorFilters.length) {
			allSelectedColorFilters[allSelectedColorFilters.length - 1].insertAdjacentElement('afterend', colorFilter);
			return;
		}
		const compare = (val1: HTMLElement) => filters.findIndex(e => val1.dataset.filterforcategory === e.dataset.filtercategory);
		const filters = expressQuerySelectorAll(document, '[data-typeoffilter][data-filtercategory]') as HTMLElement[];
		const selectedFiltersChildren = expressQuerySelectorAll<HTMLElement>(selectedFiltersContainerEl, '[data-filterforcategory]');
		const index = findInsertIndex(selectedFiltersChildren, colorFilter, compare);
		const elementToInsertBefore = selectedFiltersChildren[index];
		if (!elementToInsertBefore || index <= -1 || index > selectedFiltersChildren.length) {
			selectedFiltersContainerEl.appendChild(colorFilter);
			return;
		}
		elementToInsertBefore.insertAdjacentElement('beforebegin', colorFilter);
	};

	const removeColorFilter = () => {
		filterEl.classList.remove("a-icon--selected");
		filterEl.classList.add("a-icon--unselected");

		updateParametersForUrl(TypeOfFilter.Icons, queryString, selectedFilterName, true, filterEl.dataset.analytics, possibleUrlValues);
		removeFromFilterContainer(containerEl, selectedFiltersContainerEl, filterCategory, selectedFilterName);
		onFilter && onFilter(true, queryString, filterEl.dataset.filterelement, false, containerEl, true);
	};

	const toggleColorFilter = () => {
		const selected = filterEl.classList.contains("a-icon--selected");
		onFilter && onFilter(true, queryString, filterEl.dataset.filterelement, !selected, containerEl, true);

		if (selected) {
			filterEl.classList.remove("a-icon--selected");
			filterEl.classList.add("a-icon--unselected");

			removeFromFilterContainer(containerEl, selectedFiltersContainerEl, filterCategory, selectedFilterName);
		} else {
			filterEl.classList.remove("a-icon--unselected");
			filterEl.classList.add("a-icon--selected");

			addColorToFilterContainer(containerEl, selectedFiltersContainerEl, selectedFilterName, filterCategory, removeColorFilter);
		}

		updateParametersForUrl(TypeOfFilter.Icons, queryString, filterEl.dataset.filterelement, selected, filterEl.dataset.analytics, possibleUrlValues);
	};

	if (selectedFiltersContainerEl)
		attachRemoveFilterEvent(selectedFiltersContainerEl, filterCategory, removeColorFilter, filterEl.dataset.filterelement);

	expressEventListener(filterEl, 'click', () => toggleColorFilter());

	if (selectedFilter && selectedFilter.includes(filterEl.dataset.filterelement.toLowerCase())) {
		if (filterEl.classList.contains('a-icon--selected'))
			onFilter && onFilter(true, queryString, filterEl.dataset.filterelement, true, containerEl, false);
		collapsableComponent.open();
		toggleResetFilters(containerEl, false);
	}
}
