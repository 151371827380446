export enum TemplateType { Text = 0, Icon = 1 }
export enum TypeOfFilter { Regular = 0,	MultiSelect = 1, Icons = 2, Slider = 3 }
export interface IFilterDependencies {
	readonly onFilter?: (multiselect: boolean, filterName: string, filterValue: string, checked: boolean, containerEl: HTMLElement, updateValue?: boolean) => void;
	readonly addFilterTemplate?: (templateType: TemplateType, filterCategory: string, selectedFilterName: string, htmlOrColor: string, removeFilterEvent: any) => HTMLDivElement;
	readonly attachRemoveFilterEvent?: (selectedFiltersContainerEl: HTMLElement, filterForCategory: string, removeEvent: any, filterForCategoryValue?: string) => void;
	readonly removeFromFilterContainer?: (filtersContainerEl: HTMLElement, selectedFiltersContainerEl: HTMLElement, filterCategory: string, filterCategoryValue?: string) => void;
	readonly showSelectedFilters?: (filtersContainerEl: HTMLElement, selectedFiltersContainerEl: HTMLElement) => void;
	readonly toggleResetFilters?: (filtersContainerEl: HTMLElement, force?: boolean) => void;
	readonly updateParametersForUrl?: (typeOfFilter: TypeOfFilter, filterQs: string, msg: string, removeFromUrl: boolean, analytics?: string, possibleValues?: string[]) => void;
	readonly addTextToFilterContainer?: (filtersContainerEl: HTMLElement, typeOfFilter: TypeOfFilter, selectedFiltersContainerEl: HTMLElement, filterCategory: string, selectedFilterValue: string, removeFilterEvent: any, selectedFilterName?: string) => void;
}

