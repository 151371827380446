﻿import { deleteRequest, getHtmlRequest, postRequest } from "../utils/fetch";
import { stringToBase64 } from "../utils/string";

export interface IToggleSubscription {
	applicationId: string;
	personId: string;
	pointOfSaleId: string;
	language: string;
	email: string;
	source: string;
	newsletter: boolean;
	personalMessage?: boolean;
	mailFrequency?: number;
	postalMail?: boolean;
	emarsysData?: string;
}

export interface ISubscription {
	applicationId: string;
	pointOfSaleId: string;
	language: string;
	email: string;
	source: string;
}

export interface IUnSubscription {
	applicationId: string;
	pointOfSaleId: string;
	personId: string;
	emarsysData?: string;
}

export function subscribeAsync(email: string, appId: string, posId: string, lang: string, source?: string) {
	const subscription: ISubscription = {
		applicationId: appId,
		pointOfSaleId: posId,
		language: lang,
		email,
		source
	};

	return postRequest<ISubscription, void>(window.context.ecomApiUrl + "/subscriptions/subscribe", subscription, { headers: new Headers({ 'Content-type': 'application/json; charset=utf-8' }) });
}

export function toggleSubscribeAsync(personId: string, email: string, appId: string, posId: string, lang: string, source?: string, newsletter?: boolean, personalMessage?: boolean, mailFrequency?: number, postalMail?: boolean, emarsysData?: string) {
	const toggleSubscription: IToggleSubscription = {
		personId,
		applicationId: appId,
		pointOfSaleId: posId,
		language: lang,
		email,
		source,
		newsletter,
		personalMessage,
		mailFrequency,
		postalMail,
		emarsysData
	};

	return postRequest<IToggleSubscription, void>(window.context.ecomApiUrl + "/subscriptions/togglesubscribe", toggleSubscription, { headers: new Headers({ 'Content-type': 'application/json; charset=utf-8' }) });
}

export function unSubscribeAsync(personId: string, appId: string, posId: string, emarsysData?: string) {
	const unSubscription: IUnSubscription = {
		applicationId: appId,
		pointOfSaleId: posId,
		personId: personId,
		emarsysData: emarsysData
	};

	return deleteRequest<IUnSubscription, void>(window.context.ecomApiUrl + "/subscriptions/unsubscribe", unSubscription, { headers: new Headers({ 'Content-type': 'application/json; charset=utf-8' }) });
}

export enum Gender {
	Undefined = 0,
	Male = 1,
	Female = 2,
	Neutral = 3
}

export interface IAccount {
	firstName: string;
	lastName: string;
	email: string;
	gender: Gender;
	dateOfBirth: Date | null;
	mobileNumber: string;
	personalNumber?: string;
}

export enum UpdateAccountResult {
	success = 0,
	duplicateEmail = 1,
	duplicateEmailInOtherDomain = 2
}

export function updateAccountAsync(pointOfSaleId: string, account: IAccount): Promise<IUpdateAccountResponse> {
	const args = {
		pointOfSaleId,
		account: account
	};
	return postRequest<{ pointOfSaleId, account }, IUpdateAccountResponse>('/apicore/myaccountapi/updateaccount', args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}

export interface IMailInOtherDomainError {
	readonly urlWithLink: string,
	readonly accountEmail: string
}

export interface ISetPasswordResponse {
	readonly status: ChangePasswordStatus,
	readonly label: string
}

export interface IUpdateAccountResponse {
	readonly status: UpdateAccountResult,
	readonly label: string
}

export function getDuplicateMailInOtherDomainError(accountEmail: string, pointOfSaleId: string): Promise<IMailInOtherDomainError> {
	const args = {
		accountEmail,
		pointOfSaleId
	};

	return postRequest<{ accountEmail, pointOfSaleId }, IMailInOtherDomainError>('apicore/myaccount/getduplicatemailinotherdomainerror', args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}

export enum ChangePasswordStatus {
	OK = 0,
	InvalidFormat,
	EqualToOld,
	InvalidOld,
	InvalidNew,
	WeakPassword,
	Error
}
export function setPasswordAsync(oldPassword: string, newPassword: string, pointOfSaleId: string): Promise<ISetPasswordResponse> {
	const args = { oldPassword, newPassword, pointOfSaleId };

	args.oldPassword = stringToBase64(oldPassword);
	args.newPassword = stringToBase64(newPassword);
	return postRequest('/apicore/myaccountapi/setpassword', args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}

export function getAddressTiles(currentpage: string): Promise<string> {
	return getHtmlRequest(`/apicore/myaccountapi/getaddresstiles?currentpage=${currentpage}`, { cache: "no-cache" });
}

export enum OkResult {
	OK = 0
}

export function setAccountPreferencesAsync(configKey: string, value: boolean): Promise<OkResult> {
	const args = {
		pointOfSaleId: window.context.pointOfSaleId,
		configKey,
		value: value.toString()
	};

	return postRequest('/apicore/myaccountapi/setaccountpreferences', args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}

export function saveProductPreferencesAsync(disableAutoRedEyeRemoval: boolean, enableToggleimageenhancement: boolean): Promise<OkResult> {
	const args = {
		pointOfSaleId: window.context.pointOfSaleId,
		disableAutoRedEyeRemoval,
		enableToggleimageenhancement
	};

	return postRequest('/apicore/myaccountapi/saveproductpreferences', args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}

export function setImageEnhancementAsync(enable: boolean): Promise<OkResult> {
	const args = {
		enable
	};

	return postRequest('/apicore/myaccountapi/toggleimageenhancement', args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}

export interface IGdprAccountResult {
	success: boolean;
	info: string;
}

export function actionByMethodOnAccountAsync(method: string, password: string): Promise<IGdprAccountResult> {
	const args = {
		password
	};

	return postRequest('/apicore/myaccountapi/' + method, args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}

export interface IGdprDownload {
	gdprDownloadLink: string;
}

export function canDownloadGdprDataAsync(): Promise<IGdprDownload> {
	const args = {};

	return postRequest('/apicore/myaccountapi/CanDownloadGdprData', args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}

export interface ICheckAccountValidityResponse {
	readonly isValid: boolean;
}

export function checkAccountValidityAsync(oldAccountId: string, newAccountId: string): Promise<ICheckAccountValidityResponse> {
	const args = {
		oldAccountId,
		newAccountId
	};

	return postRequest('/apicore/myaccountapi/CheckAccountValidity', args, { headers: { 'Content-type': 'application/json; charset=utf-8' } });
}
