﻿import { escapeForJavascript } from "./string";
export interface IErrorLog {
	message: string;
	url?: string;
	line?: number;
	process: string;
}

const redirectTo500 = () => {
	if (window.location.pathname.indexOf('500.aspx') === -1) window.location.replace("/error");
};

const redirectToLogin = () => {
	if (window.location.pathname.indexOf('login.aspx') === -1) window.location.replace("/login.aspx");
};

const logError = async (errorLog: IErrorLog) => {
	await postRequest<IErrorLog, string>("/api/v1/ApplicationService.svc/json/LogJavascriptError", errorLog, { headers: new Headers({ 'Content-type': 'application/json; charset=utf-8' }) });
};

const http = async <T>(path: string, config: RequestInit, keepCase?: boolean): Promise<T> => {
	const finalPath = keepCase ? path : path.toLowerCase();
	const request = new Request(finalPath, config);
	return fetch(request)
		.then(res => {
			if (!res.ok) {
				HandleError(res.status, path, res.statusText).then(_ => {
					return Promise.reject(res.statusText);
				});
			} else {
				return res.json().catch(() => ({}));
			}
		}).catch(error => {
			HandleError(error.status, path, error.statusText).then(_ => {
				return Promise.reject(error.statusText);
			});
		});
};

const httpText = async (path: string, config: RequestInit): Promise<string> => {
	const response = await getHttpResponse(path.toLowerCase(), config);
	return response.ok ? response.text() : window.context.apiFailMsg || '';
};

const HandleError = async (status: number, path: string, statusText: string) => {
	switch (status) {
		case 500:
			if (path.startsWith(window.context.ecomApiUrl)) {
				return logError({
					line: 0,
					message: escapeForJavascript(statusText),
					process: 'Browser',
					url: path
				});
			}
			redirectTo500();
			break;
		case 450:
		case 401:
			redirectToLogin();
			break;
		case 403:
			break;
		default:
			// We can't call the logger service here => circular ref
			await logError({
				line: 0,
				message: escapeForJavascript(statusText),
				process: 'Browser',
				url: path
			});
			break;
	}
};

export async function getHttpResponse(path: string, config?: RequestInit): Promise<Response> {
	const request = new Request(path.toLowerCase(), config);
	const response = await fetch(request);
	if (!response.ok) {
		await HandleError(response.status, path, response.statusText);
		return Promise.reject(response.statusText);
	}
	return response;
}

export async function getRequest<T>(path: string, config?: RequestInit, keepCase?: boolean): Promise<T> {
	const init = { method: 'get', ...config };
	return await http<T>(path, init, keepCase);
}

export async function postRequest<T, U>(path: string, body: T, config?: RequestInit, traditionalBody?: boolean): Promise<U> {
	const init = { method: 'post', body: traditionalBody && body instanceof URLSearchParams ? body as unknown as BodyInit : JSON.stringify(body), ...config };
	return await http<U>(path, init);
}

export async function deleteRequest<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
	const init = { method: 'delete', body: JSON.stringify(body), ...config };
	return await http<U>(path, init);
}

export async function postRequestWithoutBody<U>(path: string, config?: RequestInit): Promise<U> {
	const init = { method: 'post', ...config };
	return await http<U>(path, init);
}

export async function getHtmlRequest(path: string, config?: RequestInit): Promise<string> {
	const init = { method: 'get', ...config };
	return await httpText(path, init);
}

export async function postHtmlRequest<T>(path: string, body: T, config?: RequestInit): Promise<string> {
	const init = { method: 'post', body: JSON.stringify(body), ...config };
	return await httpText(path, init);
}

/* unused for now
export async function putRequest<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
	const init = { method: 'put', body: JSON.stringify(body), ...config };
	return await http<U>(path, init);
}*/

export function isValidPartialViewResponse(res: string) {
	return res.indexOf('<html>') <= 0;
}
