import {ICollapsable} from "../collapsable";
import {getParameter} from "../../utils/querystring";
import {expressEventListener, expressQuerySelector} from "../../common/html";
import {trackFiltersEvent} from "../../common/events";
import {IFilterDependencies, TypeOfFilter} from "../../common/filter";

export function createCheckBoxSelectFilter(collapsableComponent: ICollapsable, typeOfFilter: TypeOfFilter, selectedFiltersContainerEl: HTMLElement, filterqs: string, filterCategory: string, filterEl: HTMLElement, checkboxEls: HTMLElement[], possibleValues: string[], containerEl: HTMLElement, deps: IFilterDependencies) {
	const selectedFilterName = filterEl.dataset.filterelement;
	const selectedFilter = decodeURIComponent(getParameter(filterqs)).split(',');
	const selectedLabel = expressQuerySelector<HTMLElement>(filterEl, '.technical-label', false);
	const regExpForNumberInParentheses = /\(([0-9]+)\)$/;

	const {
		updateParametersForUrl,
		removeFromFilterContainer,
		onFilter,
		attachRemoveFilterEvent,
		toggleResetFilters,
		addTextToFilterContainer
	} = deps;

	const removeSingleSelectFilter = () => {
		if (typeOfFilter === TypeOfFilter.Regular)
			checkboxEls.forEach(el => {
				const checkboxEl = expressQuerySelector<HTMLInputElement>(el, 'input[type=checkbox],input[type=radio]', true);
				checkboxEl.checked = false;
			});
		else {
			const filterCheckboxEl = expressQuerySelector<HTMLInputElement>(filterEl, 'input[type=checkbox],input[type=radio]', true);
			filterCheckboxEl.checked = false;
		}

		if (onFilter)
			onFilter(typeOfFilter !== TypeOfFilter.Regular, filterqs, filterCheckboxEl.value, filterCheckboxEl.checked, containerEl, true);

		updateParametersForUrl(typeOfFilter, filterqs, selectedFilterName, true, filterEl.dataset.analytics, possibleValues);
		removeFromFilterContainer(containerEl, selectedFiltersContainerEl, filterCategory, selectedFilterName);
	};

	const addSingleSelectFilter = (checkBoxEl: HTMLInputElement) => {
		if (typeOfFilter === TypeOfFilter.Regular)
			checkboxEls.filter(f => f.dataset.filterelement != selectedFilterName).forEach(el => {
				const checkboxEl = expressQuerySelector<HTMLInputElement>(el, 'input[type=checkbox],input[type=radio]', false);
				if (checkboxEl)
					checkboxEl.checked = false;
			});

		if (checkBoxEl.checked)
			addTextToFilterContainer(containerEl, typeOfFilter, selectedFiltersContainerEl, filterCategory, selectedFilterName, removeSingleSelectFilter, filterEl.dataset.selectedtext || selectedLabel?.textContent.replace(regExpForNumberInParentheses, ''));
		else
			removeFromFilterContainer(containerEl, selectedFiltersContainerEl, filterCategory, selectedFilterName);
	};

	if (selectedFiltersContainerEl)
		attachRemoveFilterEvent(selectedFiltersContainerEl, filterCategory, removeSingleSelectFilter, selectedFilterName);

	const filterCheckboxEl = expressQuerySelector<HTMLInputElement>(filterEl, 'input[type=checkbox],input[type=radio]', true);
	expressEventListener(filterEl, 'change', function () {
		if (onFilter)
			onFilter(typeOfFilter !== TypeOfFilter.Regular, filterqs, filterCheckboxEl.value, filterCheckboxEl.checked, containerEl, true);
		if (filterqs)
		{
			addSingleSelectFilter(filterCheckboxEl);
			updateParametersForUrl(typeOfFilter, filterqs, filterEl.dataset.filterelement, !filterCheckboxEl.checked, filterEl.dataset.analytics, possibleValues);
		}
		else {
			trackFiltersEvent('FilterClicked', { filter: `${filterCategory}_${filterEl.dataset.analytics || filterEl.dataset.filterelement}` });
			addSingleSelectFilter(filterCheckboxEl);
		}
	});

	if (selectedFilter && filterCheckboxEl && selectedFilterName && selectedFilter.includes(selectedFilterName.toLowerCase())) {
		const valueWasUpdated = !filterCheckboxEl.checked;
		filterCheckboxEl.checked = true;

		if (onFilter)
			onFilter(typeOfFilter !== TypeOfFilter.Regular, filterqs, filterCheckboxEl.value, filterCheckboxEl.checked, containerEl, valueWasUpdated);
		toggleResetFilters(containerEl, false);
		addSingleSelectFilter(filterCheckboxEl);
		collapsableComponent.open();
	}
}
